import ImageManage from '../image'

function App() {
  return (
    <div className="App">
      <ImageManage />
    </div>
  );
}

export default App;
